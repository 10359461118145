<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <!-- <slot name="text-content"></slot> -->
      <invoice-print-options v-model="invoicePrintOptions" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="ok()"
        data-tooltip="Generate report(s)"
        :disabled="okDisabled">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-printer" />
        </span>
        <span>Ok</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'
import InvoicePrintOptions from './InvoicePrintOptions'
import Guid from '@/components/Guid'
import { Emailer } from '@/classes'
import StoreUtil from '@/store/utils'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { RemarkTypes, InvoiceTypes, AssetTypes,EmailAssetTypes } from '@/enums'
import { LabourPrintOptionTypes } from '../QuoteService'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'InvoicePrintOptionsModal',
  components: {
    BaseModalEx,
    InvoicePrintOptions
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-printer'
    },
    okDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      innerValue: null,
      invoicePrintOptions: {
        detail: true,
        itemNumber: true,
        individualPartPrice: true,
        PartPriceInclMarkup: false,
        labourPrintOption: LabourPrintOptionTypes.Detailed
      },
      reportName: this.value.excessWithGst ? 'rptInvoiceWithGST' : 'rptInvoiceWithoutGST'
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    invoice() {
      return this.value.invoices.find(i => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.Quote)
    },
    gstInvoice() {
      return this.value.invoices.find(i => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.Gst)
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    'invoicePrintOptions.itemNumber': function(newVal) {
      this.$setInvoicePrintShowItemNo(newVal)
    }
  },
  created() {
    this.$loadPrintOptions()
    this.invoicePrintOptions.itemNumber = this.$printOptions.invoice.showItemNo
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    ok() {
      this.printInvoice()
      if (this.gstInvoice) {
        this.printGstInvoice()
      }
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('ok')
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('cancel')
    },
    printInvoice() {
      // let reportName = this.value.excessWithGst ? 'rptInvoiceWithGST' : 'rptInvoiceWithoutGST'
      const params = {
        QuoteID: this.value.quoteId,
        InvoiceNo: this.invoice ? `${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}/${this.invoice.invoiceType}` : '',
        InvoiceDate: this.formatDateLocale(this.invoice.invoiceDate, this.$userInfo.locale),
        InsurerID: this.value.insurerId,
        CustomerID: this.value.customerId,
        CompanyID: this.value.companyId,
        GSTOnly: this.gstInvoice ? 'YES' : 'NO',
        QuoteDiscount: `${this.invoice.quoteDiscount}`,
        InvoiceID: this.invoice.invoiceId,
        qiID1: Guid.empty(),
        qiID2: Guid.empty(),
        qiID3: Guid.empty(),
        qiID4: Guid.empty(),
        qiID5: Guid.empty(),
        qiID6: Guid.empty(),
        qiID7: Guid.empty(),
        qiID8: Guid.empty(),
        qiID9: Guid.empty(),
        qiID10: Guid.empty(),
        qiID11: Guid.empty(),
        qiID12: Guid.empty(),
        qiID13: Guid.empty(),
        qiID14: Guid.empty(),
        qiID15: Guid.empty(),
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }
      if (this.invoicePrintOptions.detail) {
        params.IndividualPart = this.invoicePrintOptions.individualPartPrice ? 'YES' : 'NONE'
        params.PartPriceInclMarkup = this.invoicePrintOptions.PartPriceInclMarkup ? 'YES' : 'NONE'
        params.ItemNo = this.invoicePrintOptions.itemNumber ? 'YES' : 'NONE'
        if (this.invoicePrintOptions.labourPrintOption === LabourPrintOptionTypes.LumpSum) {
          this.reportName = this.invoice.includedQuotes.length === 1 ? `${this.reportName}_LumpSum` : `${this.reportName}_LumpSumAdds`
          params.LumpSum = 'YES'
          params.Category = 'NO'
        } else if (this.invoicePrintOptions.labourPrintOption === LabourPrintOptionTypes.Category) {
          this.reportName = this.invoice.includedQuotes.length === 1 ? `${this.reportName}_LumpSum` : `${this.reportName}_LumpSumAdds`
          params.LumpSum = 'NONE'
          params.Category = 'YES'
        } else {
          this.reportName = this.invoice.includedQuotes.length === 1 ? `${this.reportName}` : `${this.reportName}Additionals`
        }
      } else {
        params.TotalExGST = `${this.invoice.TotalExGst}`
        params.QuoteNum = this.value.quoteNo

        if (this.invoice.includedQuotes.length > 1 && this.reportName == 'rptInvoiceWithGST') {
          this.reportName = 'rptInvoice'
        }
        this.reportName = this.invoice.includedQuotes.length === 1 ? `${this.reportName}_OneLineInvoice` : `${this.reportName}_OneLineAdditionalInvoice`
      }

      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e}=${params[e]}`)
      //     .join('&')
      // )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.value.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.remark = `Invoice ${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}`
      emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      emailer.subject = `Invoice ${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}`
      emailer.reportName = `Invoice_${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.reportName },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    printGstInvoice() {
      const params = {
        QuoteID: this.value.quoteId,
        InsurerID: this.value.insurerId,
        CustomerID: this.value.customerId,
        CompanyID: this.value.companyId,
        InvoiceDate: this.formatDateLocale(this.gstInvoice.invoiceDate, this.$userInfo.locale),
        InvoiceNo: this.gstInvoice.invoiceNo,
        InvoiceID: this.gstInvoice.invoiceId,
        qiID1: Guid.empty(),
        qiID2: Guid.empty(),
        qiID3: Guid.empty(),
        qiID4: Guid.empty(),
        qiID5: Guid.empty(),
        qiID6: Guid.empty(),
        qiID7: Guid.empty(),
        qiID8: Guid.empty(),
        qiID9: Guid.empty(),
        qiID10: Guid.empty(),
        qiID11: Guid.empty(),
        qiID12: Guid.empty(),
        qiID13: Guid.empty(),
        qiID14: Guid.empty(),
        qiID15: Guid.empty(),
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e}=${params[e]}`)
      //     .join('&')
      // )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.value.quoteId
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.remark = `GST Invoice ${this.gstInvoice.prefix}${this.gstInvoice.invoiceNo}${this.gstInvoice.suffix}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `GST Invoice ${this.gstInvoice.prefix}${this.gstInvoice.invoiceNo}${this.gstInvoice.suffix}`
      emailer.reportName = `GST-Invoice_${this.gstInvoice.prefix}${this.gstInvoice.invoiceNo}${this.gstInvoice.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'rptInvoiceGSTOnly' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    formatDateLocale(value, locale, format = '2-digit') {
      const date = new Date(Date.parse(`${value}`))
      const options = { year: 'numeric', month: format, day: '2-digit' }
      if (Intl) {
        return Intl.DateTimeFormat(locale, options).format(date)
      } else {
        return date.toLocaleDateString(locale, options)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
