<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        Search Invoice No.
      </p>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <div class="control">
          <label class="label">Invoice No.</label>
          <input class="input"
            v-model="invoiceNo"
            @input="searchInvoice"
            placeholder="Start typing to search..."
            v-mask="'##########'"
            v-focus-inserted>
          <span v-if="isInvoiceNoAvailable && invoiceNo.length > 0"
            class="help is-success">Invoice No. available</span>
          <span v-else-if="!isInvoiceNoAvailable && invoiceNo.length > 0"
            class="help is-danger">Invoice No. is not available</span>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="ok()"
        data-tooltip="Start copying images"
        :disabled="!isInvoiceNoAvailable">Ok</button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Close">Close</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { QuoteInvoiceService } from '../services'
import { BaseModalEx } from '@/components/BulmaModal'
import _debounce from 'lodash.debounce'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'InvoiceNoSearchModal',
  directives: {
    FocusInserted
  },
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-magnify'
    },
    invoiceType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isActive: false,
      invoiceNo: '',
      isInvoiceNoAvailable: false
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    ok() {
      this.$emit('ok', this.invoiceNo)
    },
    close() {
      this.$emit('close')
    },
    searchInvoice: _debounce(async function() {
      if (this.invoiceNo) {
        this.isInvoiceNoAvailable = await QuoteInvoiceService.assertInvoiceNoAvailable(this.invoiceNo, this.invoiceType)
      } else {
        this.isInvoiceNoAvailable = false
      }
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
