<template>
  <div>
    <div class="columns">
      <div class="column is-flex is-align-items-center">
        <p class="title is-size-5">Invoice Control</p>
      </div>
      <div class="buttons">
        <div v-if="selectedInvoice"
          class="is-flex is-justify-content-flex-end mr-2">
          <button @click="voidInvoice"
            class="button is-danger is-outlined">
            <span class="icon"><i class="mdi mdi-delete mdi-18px" /></span>
            <span>Void Invoice</span></button>
        </div>
        <div v-if="selectedInvoice"
          class="is-flex is-justify-content-flex-end">
          <button @click="previewInvoice"
            class="button is-outlined">
            <span class="icon"><i class="mdi mdi-printer mdi-18px" /></span>
            <span>Print Invoice</span></button>
        </div>
      </div>
    </div>
    <div v-if="selectedInvoice">
      <div class="columns">
        <div class="column">
          <label class="label">Invoice No.</label>
          <input class="input is-static"
            :value="invoiceNoReference"
            type="text"
            readonly>
        </div>
        <div class="column">
          <label class="label">Invoice Date</label>
          <input class="input"
            :value="$filters.formatDateTimezone(selectedInvoice.invoiceDate, $userInfo.locale)"
            type="text"
            disabled>
        </div>
      </div>
      <div class="is-divider mt-3 mb-4" />
      <div>
        <table v-if="jobsInvoiced.length > 0"
          class="table is-narrow is-fullwidth">
          <tr>
            <th class="has-vertical-middle">Jobs Invoiced</th>
            <th class="has-vertical-middle has-text-right">Inv. Amount Ex GST</th>
          </tr>
          <tr v-for="assessment in jobsInvoiced"
            :key="assessment.assessmentId">
            <td>{{ assessment.subQuoteNo === 0 ? 'Main' : `Supp-${assessment.subQuoteNo}` }}</td>
            <td class="has-vertical-middle has-text-right money-input">{{ $filters.formatCurrency(assessment.invoicedAmount, $userInfo.locale) }}</td>
          </tr>
        </table>
        <div>
          <div class="columns">
            <div v-if="isMainInvoiceIncluded || selectedInvoice.invoiceType === invoiceTypes.Excess"
              class="column">
              <div class="field">
                <label class="label">Excess</label>
                <vue-numeric class="input has-text-right"
                  v-model.number="selectedInvoice.excessAmount"
                  :min:="0"
                  :precision="2"
                  disabled />
              </div>
            </div>
            <div v-if="isMainInvoiceIncluded || selectedInvoice.invoiceType === invoiceTypes.OwnerContribution"
              class="column">
              <label class="label">Owner Contribution</label>
              <vue-numeric class="input has-text-right"
                v-model.number="selectedInvoice.ownerContribution"
                :min:="0"
                :precision="2"
                disabled />
            </div>
            <div v-if="selectedInvoice.invoiceType === invoiceTypes.Excess || selectedInvoice.invoiceType === invoiceTypes.OwnerContribution"
              class="column" />
          </div>
          <div class="columns">
            <div v-if="isMainInvoiceIncluded"
              class="column">
              <div class="field">
                <label class="label">Discount</label>
                <vue-numeric class="input has-text-right"
                  v-model.number="selectedInvoice.quoteDiscount"
                  :min:="0"
                  :precision="2"
                  disabled />
              </div>
            </div>
            <div v-if="selectedInvoice.invoiceType !== invoiceTypes.Excess"
              class="column">
              <div class="field">
                <label class="label">GST</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model.number="selectedInvoice.gst"
                    :minus="true"
                    :precision="2"
                    disabled />
                </div>
              </div>
            </div>
            <div v-if="selectedInvoice.invoiceType !== invoiceTypes.Excess && !isMainInvoiceIncluded"
              class="column" />
          </div>
        </div>
        <div class="field mt-2">
          <div class="control">
            <input type="checkbox"
              v-model="selectedInvoice.exported"
              class="is-checkradio"
              id="invoice-exported"
              disabled>
            <label for="invoice-exported">Exported</label>
          </div>
        </div>
        <div class="field">
          <label class="label">Total Excl GST: <span class="has-text-success">{{ selectedInvoice.totalExGst | formatCurrency($userInfo.locale) }}</span></label>
        </div>
        <div class="field">
          <label class="label">Total Payable Incl GST: <span class="has-text-success">{{ selectedInvoice.totalIncGst | formatCurrency($userInfo.locale) }}</span></label>
        </div>
        <div v-if="isOverrideEnabled">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Total Labour</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="selectedInvoice.labourOverride"
                    :min:="0"
                    disabled
                    :precision="2" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">R &amp; R</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="selectedInvoice.rrOverride"
                    :min:="0"
                    disabled
                    :precision="2" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Repair</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="selectedInvoice.repairOverride"
                    :min:="0"
                    disabled
                    :precision="2" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Paint</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="selectedInvoice.paintOverride"
                    :min:="0"
                    disabled
                    :precision="2" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Mech</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="selectedInvoice.mechOverride"
                    :min:="0"
                    disabled
                    :precision="2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal v-if="isConfirmVoidActive"
      :active.sync="isConfirmVoidActive"
      @ok="confirmVoidInvoice()"
      @cancel="isConfirmVoidActive = false"
      :ok-text="'Yes'"
      :ok-class="'is-success'"
      :cancel-text="'No'">
      <p slot="text-title">Void Invoice</p>
      <p slot="text-content">
        <span>Are you sure you want to void the invoice? Proceeding will result in voiding invoice/s: </span>
        <span class="bold-text">{{ bindedInvoicesString }}</span>
      </p>
    </confirm-modal>
    <assessment-invoice-print-options-modal v-if="isInvoicePrintOptionsActive"
      :active.sync="isInvoicePrintOptionsActive"
      @ok="printInvoice"
      @cancel="isInvoicePrintOptionsActive = false"
      :show-icon="true"
      :cancel-disabled="false"
      :value="value"
      :invoice="selectedInvoice">
      <p slot="text-title">Invoice Print Options</p>
    </assessment-invoice-print-options-modal>
  </div>
</template>

<script>
import { InvoiceTypes, AssetTypes, RemarkTypes } from '@/enums'
import { LabourPrintOptionTypes } from '../QuoteService'
import VueNumeric from '@/components/VueNumeric'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { ConfirmModal } from '@/components/BulmaModal'
import { AssessmentInvoicePrintOptionsModal } from '../components'
import { KeyValuePairModel } from '@/classes/viewmodels'
import StoreUtil from '@/store/utils'
import { Emailer } from '@/classes'

export default {
  name: 'InvoiceControlView',
  components: {
    VueNumeric,
    ConfirmModal,
    AssessmentInvoicePrintOptionsModal
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    selectedInvoice: null
  },
  data() {
    return {
      isConfirmVoidActive: false,
      isInvoicePrintOptionsActive: false
    }
  },
  computed: {
    invoiceTypes() {
      return InvoiceTypes
    },
    invoiceNoReference() {
      return this.getInvoiceReferenceNo(this.selectedInvoice)
    },
    invoiceTypeName() {
      return InvoiceTypes.getDisplayName(this.selectedInvoice.invoiceType)
    },
    jobsInvoiced() {
      return this.value.quoteAssessments.filter((assessment) =>
        this.selectedInvoice.includedAssessments.some((includedAssessment) => includedAssessment === assessment.assessmentID)
      )
    },
    isMainInvoiceIncluded() {
      return this.jobsInvoiced.some((assessment) => assessment.subQuoteNo === 0)
    },
    isOverrideEnabled() {
      return this.selectedInvoice.labourOverride || this.selectedInvoice.rrOverride || this.selectedInvoice.panelOverride || this.selectedInvoice.partOverride
    },
    raisedInvoice() {
      return this.value.invoices.filter((i) => i.isDeleted !== true && i.deleted !== true)
    },
    bindedInvoices() {
      let result = this.raisedInvoice.filter((i) => i.invoiceNo === this.selectedInvoice.invoiceNo)
      if (this.selectedInvoice.invoiceType === InvoiceTypes.Quote || this.selectedInvoice.invoiceType === InvoiceTypes.Gst) {
        result = result.filter((i) => i.invoiceType === InvoiceTypes.Gst || i.invoiceType === InvoiceTypes.Quote)
      } else if (this.selectedInvoice.invoiceType === InvoiceTypes.Excess || this.selectedInvoice.invoiceType === InvoiceTypes.OwnerContribution) {
        result = result.filter((i) => i.invoiceType === InvoiceTypes.Excess || i.invoiceType === InvoiceTypes.OwnerContribution)
      }
      return result
    },
    bindedInvoicesString() {
      if (this.bindedInvoices && this.bindedInvoices.length > 0) {
        return this.bindedInvoices.map((i) => this.getInvoiceReferenceNo(i)).join(', ')
      }
      return ''
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    voidInvoice() {
      this.isConfirmVoidActive = true
    },
    previewInvoice() {
      if (this.selectedInvoice.invoiceType === InvoiceTypes.Quote) {
        this.isInvoicePrintOptionsActive = true
      }
      else {
        const invoicePrintOptions = {
          detail: false,
          itemNumber: false,
          individualPartPrice: false,
          PartPriceInclMarkup: false,
          labourPrintOption: LabourPrintOptionTypes.Detailed
        }
        this.printInvoice(invoicePrintOptions)
      }
    },
    confirmVoidInvoice() {
      this.$emit('void-invoice', this.bindedInvoices)
      this.isConfirmVoidActive = false
    },
    getInvoiceReferenceNo(invoice) {
      return invoice ? `${invoice.prefix}${invoice.invoiceNo}${invoice.suffix}/${invoice.invoiceType}` : ''
    },
    printInvoice(invoicePrintOptions) {
      const params = {
        QuoteID: this.value.quoteId,
        QuoteNum: this.value.quoteNo,
        InvoiceNo: this.selectedInvoice
          ? `${this.selectedInvoice.prefix}${this.selectedInvoice.invoiceNo}${this.selectedInvoice.suffix}/${this.selectedInvoice.invoiceType}`
          : '',
        InvoiceDate: this.formatDateLocale(this.selectedInvoice.invoiceDate, this.$userInfo.locale),
        CompanyID: this.value.companyId,
        InvoiceID: this.selectedInvoice.invoiceId,
        CustomerID: this.value.customerId,
        InsurerID: this.value.insurerId,
        timezoneoffset: this.$filters.getSiteTimezoneOffset(),
        detailInvoice: false,
        PartPriceInclMarkup: false,
        individualPartPrice: true,
        itemNumber: true,
        LumpSum: false,
        Category: false
      }

      if (invoicePrintOptions.detail) {
        params.detailInvoice = true
      }
      if (invoicePrintOptions.PartPriceInclMarkup) {
        params.PartPriceInclMarkup = true
      }
      if (!invoicePrintOptions.individualPartPrice) {
        params.individualPartPrice = false
      }
      if (!invoicePrintOptions.itemNumber) {
        params.itemNumber = false
      }
      if (invoicePrintOptions.labourPrintOption === LabourPrintOptionTypes.LumpSum) {
        params.LumpSum = true
      }
      if (invoicePrintOptions.labourPrintOption === LabourPrintOptionTypes.Category) {
        params.Category = true
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.value.quoteId
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.remark = `Invoice ${this.selectedInvoice.prefix}${this.selectedInvoice.invoiceNo}${this.selectedInvoice.suffix}`
      emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      emailer.subject = `Invoice ${this.selectedInvoice.prefix}${this.selectedInvoice.invoiceNo}${this.selectedInvoice.suffix}`
      emailer.reportName = `Invoice_${this.selectedInvoice.prefix}${this.selectedInvoice.invoiceNo}${this.selectedInvoice.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'rptInvoice' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
      this.isInvoicePrintOptionsActive = false
    },
    formatDateLocale(value, locale, format = '2-digit') {
      const date = new Date(Date.parse(`${value}`))
      const options = { year: 'numeric', month: format, day: '2-digit' }
      if (Intl) {
        return Intl.DateTimeFormat(locale, options).format(date)
      } else {
        return date.toLocaleDateString(locale, options)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bold-text {
  font-weight: bold;
}
</style>