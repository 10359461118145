<template>
  <div v-if="companyItemTypes">
    <table class="table is-bordered is-striped is-narrow is-fullwidth">
      <thead>
        <tr>
          <th class="is-title">Category</th>
          <th v-for="(assessment) in allAssessments"
            :key="assessment.assessmentID"
            class="has-text-right is-subtotal">
            {{ assessment.subQuoteNo === 0 ? 'Main' : `Supp-${assessment.subQuoteNo}` }}
          </th>
          <th v-if="allAssessments.length > 1"
            class="has-text-right is-subtotal">
            Summary
          </th>
          <th v-if="allAssessments.length === 0">Main</th>
        </tr>
      </thead>
      <tfoot :class="[allAssessments.length > 1 ? 'has-multiple-column-totals' : 'has-multiple-totals']">
        <tr>
          <th class="has-text-right">Total excl. GST</th>
          <th v-for="(assessment) in allAssessments"
            :key="assessment.assessmentID"
            class="has-text-right has-text-success">
            {{ getAssessmentTotal(assessment) | formatCurrency($userInfo.locale) }}
          </th>
          <th v-if="allAssessments.length > 1"
            class="has-text-right has-text-success">{{ getGrandAssessmentTotal() | formatCurrency($userInfo.locale) }}
          </th>
          <th v-if="allAssessments.length === 0"
            class="has-text-right">{{ 0 | formatCurrency($userInfo.locale) }}</th>
        </tr>
        <tr>
          <th class="has-text-right">GST</th>
          <th v-for="(assessment) in allAssessments"
            :key="assessment.assessmentID"
            class="has-text-right has-text-success">
            {{ getAssessmentTotal(assessment) * (value.gstRate/100) | formatCurrency($userInfo.locale) }}
          </th>
          <th v-if="allAssessments.length > 1"
            class="has-text-right has-text-success">{{ getGrandAssessmentTotal() * (value.gstRate/100) | formatCurrency($userInfo.locale) }}
          </th>
          <th v-if="allAssessments.length === 0"
            class="has-text-right">{{ 0 | formatCurrency($userInfo.locale) }}</th>
        </tr>
        <tr>
          <th class="has-text-right">Total incl. GST</th>
          <th v-for="(assessment) in allAssessments"
            :key="assessment.assessmentID"
            class="has-text-right has-text-success">
            {{ getAssessmentTotal(assessment) * (value.gstRate/100) + getAssessmentTotal(assessment) | formatCurrency($userInfo.locale) }}
          </th>
          <th v-if="allAssessments.length > 1"
            class="has-text-right has-text-success">{{ getGrandAssessmentTotal() * (value.gstRate/100) + getGrandAssessmentTotal() | formatCurrency($userInfo.locale) }}
          </th>
          <th v-if="allAssessments.length === 0"
            class="has-text-right">{{ 0 | formatCurrency($userInfo.locale) }}</th>
        </tr>
      </tfoot>
      <tbody>
        <tr v-for="(itemType) in companyItemTypes"
          :key="itemType.itemCategoryId">
          <td>
            {{ getDisplayName(itemType.itemCategoryType) }}
          </td>
          <td v-for="assessment in allAssessments"
            :key="assessment.assessmentID"
            class="has-text-right"> {{ getAssessmentTotal(assessment, itemType.itemCategoryType) | formatNumber($userInfo.locale) }}</td>
          <td v-if="allAssessments.length > 1"
            class="has-text-right">
            {{ getGrandAssessmentTotal(itemType.itemCategoryType) | formatNumber($userInfo.locale) }}
          </td>
          <td v-if="allAssessments.length === 0"
            class="has-text-right">{{ 0 | formatNumber($userInfo.locale) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { ItemCategoryTypes } from '@/enums'
import { QuoteAssessmentMixin } from './mixins'

export default {
  name: 'QuoteDetailAssessmentSummary',
  mixins: [NumberFiltersMixin, QuoteAssessmentMixin],
  props: {
    value: null
  },
  data() {
    return {
      draftAssessment: null
    }
  },
  computed: {
    companyItemTypes() {
      return this.$company.itemCategories.filter((i) => i.enabled)
    },
    allAssessments() {
      const all = this.value.quoteAssessments.filter((a) => a.deleted !== true)
      if (this.draftAssessment) {
        all.push(this.draftAssessment)
      }
      return all
    }
  },
  created() {
    this.createDraftAssessment()
  },
  mounted() {},
  methods: {
    getAssessmentTotal(assessment, itemCategoryType = null) {
      if (assessment.authorisedAssessment) {
        let items = itemCategoryType
          ? assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => i.itemType === itemCategoryType)
          : assessment.authorisedAssessment.assessmentQuoteItems
        return this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      } else if (assessment.submittedAssessment) {
        let items = itemCategoryType
          ? assessment.submittedAssessment.assessmentQuoteItems.filter((i) => i.itemType === itemCategoryType)
          : assessment.submittedAssessment.assessmentQuoteItems
        return this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      }
    },
    getGrandAssessmentTotal(itemCategoryType = null) {
      return this.allAssessments.reduce((acc, assessment) => {
        return acc + this.getAssessmentTotal(assessment, itemCategoryType)
      }, 0)
    },
    getQuoteTotal(itemCategoryType = null) {
      if (itemCategoryType === null) {
        return this.value.totalExGst
      }
      switch (itemCategoryType) {
        case ItemCategoryTypes.RR:
          return this.value.rrTotal
        case ItemCategoryTypes.RWA:
          return this.value.rwaTotal
        case ItemCategoryTypes.REP:
          return this.value.repairTotal
        case ItemCategoryTypes.PAINT:
          return this.value.paintTotal
        case ItemCategoryTypes.FIBER:
          return this.value.fgTotal
        case ItemCategoryTypes.CRUSH:
          return this.value.crushTotal
        case ItemCategoryTypes.CD:
          return this.value.cdTotal
        case ItemCategoryTypes.MECH:
          return this.value.mechTotal
        case ItemCategoryTypes.PART:
          return this.value.partTotal
        case ItemCategoryTypes.OPG:
          return this.value.opgTotal
        case ItemCategoryTypes.MISC:
          return this.value.miscTotal
        case ItemCategoryTypes.SUBL:
          return this.value.sublTotal
        case ItemCategoryTypes.PDRRR:
          return this.value.pdrRrTotal
        case ItemCategoryTypes.PDRRWA:
          return this.value.pdrRwaTotal
        default:
          break
      }
    },
    getDisplayName(itemCategoryType) {
      if (this.companyItemTypes) {
        const itemType = this.companyItemTypes.find((i) => i.itemCategoryType === itemCategoryType)
        if (itemType) {
          return itemType.displayName
        }
      }
      return itemCategoryType
    },
    createDraftAssessment() {
      const items = [...this.value.labours, ...this.value.others, ...this.value.parts, ...this.value.opgs, ...this.value.miscs, ...this.value.sublets]
      const itemsChanged = this.itemsChanged(items, this.value.quotingMethod)
      if (itemsChanged.length > 0) {
        this.draftAssessment = this.createAssessment(itemsChanged, this.value.quoteAssessments.length, this.value.assessorName)
      } else {
        this.draftAssessment = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
th.is-subtotal {
  width: 7em;
}
th.is-title {
  width: 12em;
}
</style>
