<template>
  <div>
    <div class="field">
      <input class="is-checkradio"
        id="detail-invoice"
        type="radio"
        name="detail-invoice"
        :value="true"
        v-model="innerValue.detail">
      <label for="detail-invoice">Detail Invoice</label>
      <input class="is-checkradio"
        id="one-line-invoice"
        type="radio"
        name="one-line-invoice"
        :value="false"
        v-model="innerValue.detail">
      <label for="one-line-invoice">1-Line Invoice</label>
    </div>
    <div class="is-divider" />
    <div class="field">
      <input class="is-checkradio"
        id="itemNumber"
        type="checkbox"
        checked="checked"
        name="itemNumber"
        v-model="innerValue.itemNumber"
        :disabled="!innerValue.detail">
      <label for="itemNumber">Item Number</label>
      <input class="is-checkradio"
        id="individualPartPrice"
        type="checkbox"
        checked="checked"
        name="individualPartPrice"
        v-model="innerValue.individualPartPrice"
        :disabled="!innerValue.detail">
      <label for="individualPartPrice">Individual Part Price</label>
    </div>
    <div class="field">
      <input class="is-checkradio"
        id="PartPriceInclMarkup"
        type="checkbox"
        checked="checked"
        name="PartPriceInclMarkup"
        v-model="innerValue.PartPriceInclMarkup"
        :disabled="!innerValue.detail">
      <label for="PartPriceInclMarkup">Part Prices incl. Markup</label>
    </div>
    <div class="is-divider" />
    <div class="field">
      <label class="label">Labour Print Options</label>
      <input class="is-checkradio"
        id="lumpSum"
        type="radio"
        name="lumpSum"
        :value="labourPrintOptionTypes.LumpSum"
        v-model="innerValue.labourPrintOption"
        :disabled="!innerValue.detail">
      <label for="lumpSum">Lump Sum</label>
      <input class="is-checkradio"
        id="category"
        type="radio"
        name="category"
        :value="labourPrintOptionTypes.Category"
        v-model="innerValue.labourPrintOption"
        :disabled="!innerValue.detail">
      <label for="category">Category</label>
      <input class="is-checkradio"
        id="detailed"
        type="radio"
        name="detailed"
        :value="labourPrintOptionTypes.Detailed"
        v-model="innerValue.labourPrintOption"
        :disabled="!innerValue.detail">
      <label for="detailed">Detailed</label>
    </div>
  </div>
</template>

<script>
import { LabourPrintOptionTypes } from '../QuoteService'

export default {
  name: 'InvoicePrintOptions',
  components: {},
  props: {
    value: null
  },
  data() {
    return {
      isDetailInvoice: true,
      isItemNumber: true,
      isIndividualPartPrice: true,
      labourOption: LabourPrintOptionTypes.Detailed,
      innerValue: this.value
    }
  },
  computed: {
    labourPrintOptionTypes() {
      return LabourPrintOptionTypes
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    'innerValue.detail': function(newVal, oldVal) {
      if (!newVal) {
        this.innerValue.labourPrintOption = LabourPrintOptionTypes.Category
      } else {
        this.innerValue.labourPrintOption = LabourPrintOptionTypes.Detailed
      }
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
