<template>
  <div>
    <div class="is-flex is-justify-content-space-between">
      <label class="label">Payer</label>
      <div class="is-flex">
        <div class="is-flex is-align-items-center is-justify-content-end mb-2">
          <label class="pretty-checkbox-label pr-1 is-size-7"
            for="chk-customer">Customer</label>
          <div class="pretty p-icon p-round p-smooth m-0">
            <input v-model="assetFilter.showCustomer"
              id="chk-customer"
              type="checkbox">
            <div class="state p-success">
              <i class="icon mdi mdi-check" />
              <label />
            </div>
          </div>
        </div>
        <div class="is-flex is-align-items-center is-justify-content-end mb-2 pl-2">
          <label class="pretty-checkbox-label pr-1 is-size-7"
            for="chk-insurer">Insurer</label>
          <div class="pretty p-icon p-round p-smooth m-0">
            <input v-model="assetFilter.showInsurer"
              id="chk-insurer"
              type="checkbox">
            <div class="state p-info">
              <i class="icon mdi mdi-check" />
              <label />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="control is-expanded">
      <multiselect v-if="assets"
        :class="{ 'is-danger' : !selectedAsset}"
        v-model="selectedAsset"
        deselect-label="Can't remove this value"
        track-by="assetId"
        label="assetName"
        placeholder="Select a payer"
        :options="assets"
        :loading="isAssetLoading"
        :internal-search="false"
        :options-limit="assetFilter.pageSize"
        @select="selectAsset"
        @search-change="getAssetDropdownFilter"
        :searchable="true"
        :show-labels="false"
        :allow-empty="true">
        <template slot="option"
          slot-scope="props">
          <span class="tag is-pulled-right"
            :class="{'is-success' : props.option.assetType == 1, 'is-info' : props.option.assetType == 4}">
            {{ props.option.assetType == 1 ? 'Customer' : 'Insurer' }}
          </span>
          <span>{{ `${props.option.assetName}` }}</span>
        </template>
        <span class="has-text-danger"
          slot="noResult">Payer not found.</span>
      </multiselect>
      <span class="help is-danger"
        v-if="!selectedAsset">Payer is required.
      </span>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import _debounce from 'lodash.debounce'
import Guid from '@/components/Guid'
import { QuoteInvoiceValidationMixin} from '../mixins'
import QuickInvoiceService from '@/views/quickinvoice/QuickInvoiceService.js'

export default {
  name: 'PayerSelector',
  components: {
    Multiselect
  },
  mixins: [QuoteInvoiceValidationMixin],
  props : {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      assetFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      selectedAsset: null,
      assets: [],
      isAssetLoading: false,
      insurerAsset: null,
      customerAsset: null
    }
  },
  computed: {
    showAsset() {
      return {
        customer: this.assetFilter.showCustomer,
        insurer: this.assetFilter.showInsurer
      }
    }
  },
  watch: {
    showAsset(newVal, oldVal) {
      if (!newVal.customer && !newVal.insurer) {
        this.assetFilter.showCustomer = !oldVal.customer
        this.assetFilter.showInsurer = !oldVal.insurer
      }
      this.getAssetDropdown()
    }
  },
  async created() {
    await this.getAssetDropdown()
  },
  methods: {
    async getAssetDropdown() {
      this.isAssetLoading = true
      this.assets = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      if (this.assetFilter.assetId !== Guid.empty()) {
        this.selectedAsset = this.assets.find((c) => c.assetId === this.assetFilter.assetId)
      }
      this.isAssetLoading = false
    },
    getAssetDropdownFilter: _debounce(async function (query) {
      this.isAssetLoading = true
      this.assetFilter.assetName = query
      this.assets = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      this.isAssetLoading = false
    }, 500),
    selectAsset(selected) {
      this.$emit('payer-select', selected)

    }
  }
}
</script>

<style lang="scss" scoped>
</style>
